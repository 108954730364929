<template lang="pug">
.menu-item(:class='menuItemClass')
  component.test-pixel-observer(v-if="item.testPixel" :is="testPixelComponent" @appear="onAppearItem")
    img.test-pixel(
      v-if="isAppear && item.testPixel"
      :src="item.testPixel"
      alt="Тестовый пиксель"
    )
  component.menu-item__link(
    :is='componentLink'
    :class="linkClass"
    :style='linkColors'
    @click.native='$emit("click-menu-item", item)'
    v-bind='attrsLink'
  )
    span.menu-item__title(:class='titleClass') {{ item.title }}
</template>

<script lang="ts">
  import type { Component, PropType } from 'vue';
  import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';
  import { getRelativeLink } from '~/utils';
  import type { ILink, TTheme } from '~/components/ArrowMenu/ArrowMenu';

  type TCategory = {
    backgroundColor: string;
    id: number;
    link: string;
    testPixel: string;
    textColor: string;
    title: string;
  };

  export default defineNuxtComponent({
    name: 'ArrowMenuItem',
    components: {
      IntersectionObserver,
    },
    props: {
      item: {
        type: Object as PropType<ILink>,
        required: true,
      },

      appearedItems: {
        type: Array as PropType<number[]>,
        default: () => [],
      },

      theme: {
        type: String as PropType<TTheme>,
        default: 'light',
      },

      currentRubric: {
        type: Object as PropType<TCategory>,
        default: null,
      },
    },

    computed: {
      testPixelComponent(): string | Component {
        return this.isAppear ? 'div' : IntersectionObserver;
      },

      isAppear(): boolean {
        return this.appearedItems?.includes(this.item.id);
      },

      isActive(): boolean {
        return useRoute().path === this.getRelativeLink(this.item.link || '');
      },

      isRedTheme(): boolean {
        return this.theme === 'red';
      },

      menuItemClass() {
        const className = 'menu-item';
        const theme = this.theme;
        return {
          [`${className}--${theme}`]: theme,
          [`${className}--active`]: this.isActive,
        };
      },

      linkClass() {
        const className = 'menu-item__link';
        const theme = this.theme;
        return {
          [`${className}--${theme}`]: theme,
          [`${className}--colored`]: this.item.backgroundColor,
          [`${className}--active`]: this.isActive,
        };
      },

      titleClass() {
        const className = 'menu-item__title';
        const theme = this.theme;
        return {
          [`${className}--${theme}`]: theme,
          [`${className}--colored`]: this.item.backgroundColor,
          [`${className}--active`]: this.isActive,
        };
      },

      componentLink(): string | Component {
        return this.isActive ? 'span' : 'a';
      },

      attrsLink() {
        return this.isActive ? {} : { href: this.item.link };
      },

      linkColors(): string | undefined | null {
        return `
        --bg: ${this.item.backgroundColor || 'none'};
        --text: ${this.item.textColor || '#5C5C5C'};
      `;
      },
    },

    methods: {
      getRelativeLink,
      onAppearItem() {
        this.$emit('appear-item', this.item.id);
      },
    },
  });
</script>

<style lang="scss" scoped>
  .test-pixel,
  .test-pixel-observer {
    height: 1px;
    width: 1px;
    position: absolute;
    opacity: 0;
    font-size: 0;
  }

  .menu-item {
    position: relative;
    padding: 0;
    align-self: stretch;
    width: fit-content;

    display: flex;
    justify-content: center;
    align-items: center;

    &--active {
      pointer-events: none;
    }

    //@include tablet {
    //  padding: 8px;
    //}

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      pointer-events: none;
    }

    &--red {
      &::before {
        background: #ffffff;
      }

      &.menu-item--active {
        &::before {
          pointer-events: none;
          z-index: -1;
          border-bottom: 1px solid #e7e7e7;
          height: unset;
          bottom: 0;
        }
      }

      &:not(.menu-item--active) {
        &::before {
          opacity: 0;
        }
        @include hover {
          &::before {
            opacity: 0.2;
          }
        }
      }
    }

    &--light {
      //@include big-tablet {
      //  padding: 10px 8px;
      //}
      &.menu-item--active::before {
        bottom: 0;
        top: unset;
        width: calc(100% - 16px);
        margin: 0 auto;
        right: 0;
        border-bottom: 3px solid var(--main-color-2);

        @include tablet {
          width: calc(100% - 24px);
        }
      }
    }

    &__link {
      height: 100%;
      padding: 4px 8px;
      white-space: nowrap;
      text-decoration: none;
      font-family: $secondFont;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 24px;

      @include tablet {
        font-size: 16px;
        line-height: 24px;
        padding: 12px;
      }

      &--light {
        font-weight: 500;

        color: #5c5c5c;

        &:not(.menu-item__link--colored).menu-item__link--active {
          color: var(--main-color-2);
        }

        @include hover {
          &:not(.menu-item__link--colored) {
            color: #000;
          }
        }

        @include big-tablet {
          font-family: $mainFont;
          font-weight: 400;
          color: var(--text);
        }
      }

      &--red {
        color: #ffffff;
        font-weight: 600;
        @include hover {
          color: #ffffff;
        }

        @include big-tablet {
          padding: 12px 16px;
        }

        &.menu-item__link--active {
          color: #000000;
        }
      }
    }

    &__title {
      color: inherit;
      font-size: inherit;
      font-family: inherit;
      &--light {
        @include big-tablet {
          background-color: var(--bg);
        }
      }
      &--colored {
        @include big-tablet {
          padding: 2px 8px;
          border-radius: 2px;
        }
      }
    }
  }
</style>
