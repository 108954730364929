<template>
  <div
    class="top-header"
    :class="topHeaderHealth"
  >
    <div class="top-header__inner">
      <div class="top-header__left">
        <!-- - иконка бургера на мобилке и планшете -->
        <div
          class="top-header__icon-menu"
          data-cy="nav-menu-burger"
          @click="showNav"
          @mouseenter="showNavMenu"
        >
          <SvgAsset
            :path="menuIconUrl"
            width="24"
            height="24"
          />
        </div>

        <div
          class="top-header__logo-lh"
          @mouseenter="showNavMenu"
          @click="showNav"
        >
          <component
            :is="isHomePage ? 'span' : 'a'"
            class="nav top-header__logo-lh-link"
            data-cy="header-logo"
            :href="isHomePage ? null : '/'"
            :title="isHomePage ? null : 'Вернуться на главную страницу сайта'"
            @focus="onLogoFocus"
            @click.stop="onLogoClick"
          >
            <!-- !ЛОГО -->
            <SvgAsset
              class="top-header__lh-logo-white"
              :path="isHealth ? healthLogoPath : logoPath"
              width="152"
              height="32"
              alt="Лайфхакер"
            />
          </component>
          <!-- иконка бургера на десктопе -->
          <SvgAsset
            v-if="!isHealth"
            class="top-header__burger-icon"
            :path="burgerIconWhitePath"
            width="24"
            height="24"
            @click="handleBurgerClick"
          />
          <a
            v-if="isHealth"
            class="top-header__health-title"
            href="/health/"
          >
            Здоровье
          </a>
        </div>

        <!-- Menu visible on desktop only -->
        <NavMenuContainer
          v-show="isNavMenuVisible"
          ref="whiteNavMenu"
          class="white"
          :is-nav-menu-visible-desktop="isNavMenuVisible"
          @mouse-left="onNavMenuMouseLeave"
          @mouse-enter="onNavMenuMouseEnter"
          @click.stop
          @click-on-logo="$emit('click-on-logo')"
        />

        <PrimaryMenu
          :primary-nav-menu-items="primaryNavMenuItems"
          location="desktop"
          @focusin="hideNavMenu"
        />
      </div>

      <div class="top-header__right">
        <button
          class="top-header__icon top-header__icon-search"
          type="button"
          aria-label="Поиск на сайте"
          @click="onClickSearch"
        >
          <SvgAsset
            :path="searchIconPath"
            width="24"
            height="24"
          />
        </button>

        <div class="top-header__icon">
          <ClientOnly>
            <TheBell
              tabindex="0"
              :is-authenticated="isAuthenticated"
              :do-authorization="doAuthorization"
            >
              <template #bellFooter>
                <div class="top-header__notifications">
                  <a
                    v-if="isAuthenticated"
                    class="top-header__notifications-link"
                    :href="`/profile/user/${userIntegerId}/notifications/`"
                  >
                    Все уведомления
                  </a>
                </div>
              </template>
            </TheBell>
          </ClientOnly>
        </div>

        <div
          class="top-header__avatar"
          data-cy="header-avatar"
        >
          <ClientOnly>
            <template v-if="isAuthenticated">
              <a
                v-if="userAvatarSrc"
                :href="`/profile/user/${userIntegerId}/notifications/`"
              >
                <img
                  class="top-header__avatar-image"
                  :src="userAvatarSrc"
                  alt="Аватар пользователя"
                />
              </a>
              <ul
                v-if="isAuthenticated"
                class="top-header__avatar-menu"
              >
                <li class="top-header__avatar-menu-item">
                  <a :href="`/profile/user/${userIntegerId}/comments`">Комментарии</a>
                </li>
                <li class="top-header__avatar-menu-item">
                  <a :href="`/profile/user/${userIntegerId}/favorites`">Избранное</a>
                </li>
                <li class="top-header__avatar-menu-item">
                  <a :href="`/profile/user/${userIntegerId}/settings`">Настройки</a>
                </li>
                <li class="top-header__avatar-menu-splitter" />
                <li class="top-header__avatar-menu-item">
                  <button
                    type="button"
                    @click.prevent="handleLogoutClick"
                  >
                    Выход
                  </button>
                </li>
              </ul>
            </template>
            <button
              v-else
              class="top-header__non-auth"
              aria-label="Авторизация"
              @click="handleAuthClick"
            >
              <SvgAsset
                :path="nonAuthIconPath"
                width="24"
                height="24"
              />
            </button>
          </ClientOnly>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import { isHomePage, isHealthPath } from '~/utils/router';
  import PrimaryMenu from '~/components/PrimaryMenu.vue';
  import NavMenuContainer from '~/containers/NavMenuContainer/NavMenuContainer.vue';
  import { isTouchDevice } from '~/utils';
  import TheBell from '@/components/Pusher/TheBell/TheBell.vue';
  import { IS_NEW_YEAR_LOGO } from '~/constants';
  import SvgAsset from '@devhacker/shared/components/SvgAsset.vue';

  import menuIconUrl from '~/assets/images/icon-menu.svg';
  import burgerIconWhitePath from '~/assets/images/burger-icon-white.svg';
  import newYearLogoWhitePath from '~/assets/images/new-year-logo-white.svg';
  import healthLogoWhitePath from '~/assets/images/health-logo-white.svg';
  import lhLogoWhitePath from '~/assets/images/lh-logo-white.svg';
  import searchIconPath from '~/assets/images/search.svg';
  import nonAuthIconPath from '~/assets/images/non-auth.svg';

  const isTouch = isTouchDevice();
  let timeoutId: NodeJS.Timeout | null = null;

  export default defineNuxtComponent({
    components: {
      SvgAsset,
      PrimaryMenu,
      NavMenuContainer,
      TheBell,
    },
    props: {
      isAuthenticated: {
        type: Boolean as PropType<boolean>,
        required: true,
      },
      doAuthorization: {
        type: Function as PropType<(location?: string) => void>,
        required: true,
      },
      userAvatarSrc: {
        type: String as PropType<string>,
        required: true,
      },
      userIntegerId: {
        type: null as unknown as PropType<number | null>,
        validator: (v: number | null) => typeof v === 'number' || v === null,
        required: true,
      },
      logout: {
        type: Function as PropType<() => void>,
        required: true,
      },
      primaryNavMenuItems: {
        type: Array as PropType<Array<MenuItem>>,
        required: true,
      },
      showNav: {
        type: Function as PropType<() => void>,
        required: true,
      },
    },
    data() {
      return {
        isNavMenuVisible: false as boolean,
      };
    },
    computed: {
      burgerIconWhitePath: () => burgerIconWhitePath,
      menuIconUrl: () => menuIconUrl,
      searchIconPath: () => searchIconPath,
      nonAuthIconPath: () => nonAuthIconPath,

      healthLogoPath(): string {
        return IS_NEW_YEAR_LOGO ? newYearLogoWhitePath : healthLogoWhitePath;
      },

      logoPath(): string {
        return IS_NEW_YEAR_LOGO ? newYearLogoWhitePath : lhLogoWhitePath;
      },

      isHealth(): boolean {
        return isHealthPath(useRoute().path);
      },
      isHomePage(): boolean {
        return isHomePage(useRoute().name);
      },
      topHeaderHealth(): Record<string, boolean> {
        return {
          'top-header__health': this.isHealth,
        };
      },
    },
    watch: {
      // при смене роута убираем NavMenu
      '$route.path'(): void {
        this.isNavMenuVisible = false;
      },
    },
    async mounted() {
      // при клике мимо NavMenu, закрываем NavMenu
      document.addEventListener(
        'click',
        () => {
          this.isNavMenuVisible = false;
        },
        false,
      );

      // * не успевает найти колокольчик
      await this.$nextTick();
      const theBell = document.querySelector('.the-bell__bell');
      theBell?.addEventListener('click', this.handleBellClick);
    },
    beforeUnmount() {
      this.clearHideMenuTimeOut();
      document.removeEventListener('click', this.handleBellClick);
    },
    methods: {
      handleBellClick(): void {
        this.$sendYandexMetrika({
          level1: 'Клик_Колокольчик',
        });
      },

      handleAuthClick(): void {
        this.$sendYandexMetrika({
          level1: 'Клик_Хедер_Профиль_Гость',
          level6: 'Открытие авторизации',
        });
        this.doAuthorization('Целенаправленная авторизация');
      },

      handleBurgerClick(): void {
        isTouch && this.showNav();
      },

      onClickSearch(): void {
        if (typeof window !== 'undefined') {
          this.$sendYandexMetrika({
            level1: 'Событие_Поиск',
            level6: 'Открытие поиска',
          });
        }

        this.$emit('click-on-search');
      },
      handleLogoutClick(): void {
        this.$sendYandexMetrika({
          level1: 'Авторизация',
          level8: 'Выход',
        });

        this.logout();
      },
      clearHideMenuTimeOut(): void {
        timeoutId && clearTimeout(timeoutId);
        timeoutId = null;
      },
      showNavMenu(): void {
        if (!isTouch && !this.isNavMenuVisible && !this.isHealth) {
          this.isNavMenuVisible = true;
        }
      },
      hideNavMenu(): void {
        if (!isTouch && this.isNavMenuVisible) {
          this.isNavMenuVisible = false;
        }
      },
      onLogoClick(): void {
        this.$emit('click-on-logo');
      },
      onNavMenuMouseLeave(): void {
        timeoutId = setTimeout(() => {
          if (this.isNavMenuVisible) {
            this.isNavMenuVisible = false;
          }
        }, 1000);
      },
      onNavMenuMouseEnter(): void {
        this.clearHideMenuTimeOut();
      },
      async onLogoFocus(): Promise<void> {
        // Непонятно зачем это
        this.showNavMenu();
        await this.$nextTick();
        if (this.$refs.whiteNavMenu instanceof HTMLElement) {
          this.$refs.whiteNavMenu.focus();
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
  $max-width: 1280px;
  $hover-color: #e1eafd;
  $icon-hover-color: rgba(255, 255, 255, 0.2);

  // Миксин для оптимизации рендера
  // На мобилках при скролле происходит тряска хедера и иконок без оптимизации
  @mixin gpuAcceleration {
    will-change: transform;
    transform: translate3d(0, 0, 0);
  }

  .top-header {
    height: 48px;
    background: var(--main-color);
    width: 100%;

    @include preview {
      // hsl, rgba, darken и т.д. не работают с css переменными в этой версии sass
      // Используем константу вместо вычисления на основе main-color
      background: var(--preview-header-color);
    }

    .icon {
      width: 24px;
      height: 24px;
    }

    &__health-title {
      @include gpuAcceleration;
      display: none;
      text-decoration: none;
      font-family: $secondFont;
      font-size: 11px;
      line-height: 24px;
      font-weight: 500;
      letter-spacing: 0.04em;
      color: #fff;
      margin-left: 12px;
      position: relative;
      text-transform: uppercase;
      margin-top: 1px;

      @include big-mobile {
        font-size: 11px;
        display: block;
      }
      @include tablet {
        font-size: 12px;
        margin-left: 16px;
      }

      &::before {
        content: '';
        width: 1px;
        background-color: #fff;
        position: absolute;
        height: 100%;
        left: -6px;
        @include tablet {
          left: -8px;
        }
      }
    }

    &__notifications {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 56px;
      z-index: 1;
      display: flex;
      box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.05);

      &-link {
        display: flex;
        width: 100%;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #2962f9;

        @include hover {
          color: #264eb1;
        }
      }
    }

    &__inner {
      max-width: $max-width;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      position: relative;
    }

    &__icon {
      @include gpuAcceleration;
      width: 48px;
      height: 48px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    &__burger-icon {
      width: 24px;
      height: 24px;
      display: none;
      @include big-tablet {
        display: initial;
      }
    }

    &__icon-search {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: none;
      background: unset;

      @include hover {
        background: $icon-hover-color;
      }
    }

    &__left {
      display: flex;
      align-items: center;
      height: 100%;
      flex-basis: 100%;
      overflow-x: hidden;
    }

    &__right {
      display: flex;
      align-items: center;
      padding-right: 4px;
      @include tablet {
        padding-right: 16px;
      }
    }
    &__right-health {
      @include big-mobile {
        max-width: 120px;
      }
      @include tablet {
        max-width: none;
      }
    }

    &__logo-lh {
      display: flex;
      align-items: center;

      @include big-tablet {
        display: flex;
        justify-content: space-between;
        padding: 0 14px 0 24px;
        flex: 0 0 224px;
      }
    }

    &__logo-lh-link {
      @include gpuAcceleration;
      display: flex;
    }

    // Перебивает icon стили, нужна вложенность
    & &__lh-logo-white {
      width: 110px;
      height: 24px;

      @include tablet {
        width: 147px;
        height: 32px;
      }
    }

    &__health {
      .top-header__lh-logo-white {
        width: 92px;
        @include tablet {
          width: 147px;
        }
      }
    }

    &__icon-menu {
      @include gpuAcceleration;
      height: 24px;
      margin: 0 8px 0 12px;
      cursor: pointer;

      @include tablet {
        margin: 0 20px 0 22px;
      }

      @include big-tablet {
        display: none;
      }
    }

    &__avatar {
      @include gpuAcceleration;
      width: 48px;
      height: 48px;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      &-image {
        display: block;
        width: 40px;
        height: 40px;
        object-fit: cover;
        object-position: center;
        background-color: #fff;
        border-radius: 50%;
        margin-bottom: 0;
      }

      @include hover {
        .top-header__avatar-menu {
          visibility: visible;
        }
        background: $icon-hover-color;
      }
    }

    &__avatar-menu {
      position: absolute;
      top: 100%;
      right: 0;
      background: white;
      width: 184px;
      padding: 4px 0;
      box-shadow: 0 3px 18px rgba(0, 0, 0, 0.25);
      border-radius: 0 0 4px 4px;
      visibility: hidden;
      list-style: none;
      display: none;
      @include tablet {
        display: block;
      }
    }

    &__avatar-menu-item {
      height: 48px;
      padding: 0 24px;
      font-size: 16px;
      color: black;
      @include hover {
        background: $hover-color;
      }

      a,
      button {
        @include removeHyperlinkFormatting;
        border: 0;
        background: transparent;
        font: inherit;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }

    &__avatar-menu-splitter {
      margin: 4px 8px;
      background: #e7e7e7;
      height: 1px;
      width: calc(100% - 16px);
      display: block;
    }

    &__non-auth {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 0;
      background: unset;
      border: none;
    }
  }
</style>
