<template>
  <div
    v-if="mascot"
    class="mascot-burger__wrapper"
    :class="wrapperClasses"
  >
    <Transition name="container-fade">
      <!-- Компонент не восстанавливается после перехода с v-if потому используется v-show для анимации -->
      <!-- Так как mascot может быть null при ошибке запроса делаем на него проверку чтобы не было падения -->
      <div
        v-show="!hideMascot"
        class="mascot-burger"
      >
        <MascotSpeech
          class="mascot-burger__speech"
          :class="speechTransform"
          :is-show="showSpeech"
          :speech-text="mascot.messageText"
          :button-text="mascot.buttonText"
          :button-link="mascot.link"
          @close-click="closeSpeech"
        />
        <div
          class="mascot-burger__lottie-wrapper"
          @mouseleave="handleMouseleave"
          @mouseover="handleMouseover"
        >
          <div
            ref="mascotBurgerAnimation"
            class="mascot-burger__lottie"
            :class="animationTransform"
            @click="onClickMascot"
          />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
  import MascotSpeech from './MascotSpeech.vue';
  import { useMascotStore } from '~/store/mascot';
  import { createScriptIfNeeded } from '../../utils/embeds';

  export default defineNuxtComponent({
    name: 'MascotBurger',

    components: {
      MascotSpeech,
    },

    data() {
      return {
        animation: null as null | any,
        hovered: false as boolean,
        showSpeech: false as boolean,
        showFullMascot: false as boolean,
        hideMascot: true as boolean,
      };
    },

    computed: {
      mascot() {
        return useMascotStore().mascotBurgerInfo;
      },

      wrapperClasses(): Record<string, boolean> {
        return {
          'mascot-burger__wrapper--shown': this.showFullMascot,
          'mascot-burger__wrapper--closed': this.hideMascot,
        };
      },

      animationTransform(): Record<string, boolean> {
        return {
          'mascot-burger__lottie--hovered': this.hovered || this.showSpeech,
          'mascot-burger__lottie--full-show': this.showFullMascot,
        };
      },

      speechTransform(): Record<string, boolean> {
        return {
          'mascot-burger__speech--full-show': this.showFullMascot,
        };
      },
    },

    watch: {
      $route() {
        this.showSpeech = false;
        this.hideMascot = false;
        this.hovered = false;
      },
    },

    async mounted() {
      await createScriptIfNeeded('/js/lottie.min.js');

      const animationData = await import('~/assets/lottie/lazeyka-top-crop.json');

      this.animation = window?.lottie?.loadAnimation({
        container: this.$refs.mascotBurgerAnimation,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        animationData: animationData.default,
        initialSegment: [0, 450],
      });

      this.playAnimation();
      this.hideMascot = false;
    },

    methods: {
      playAnimation() {
        this.animation?.play();
        this.animation.loop = true;
      },

      closeSpeech() {
        this.hideMascot = true;
        this.$emit('close-mascot');
      },

      onClickMascot() {
        this.showSpeech = true;
        this.showFullMascot = true;
        this.playAnimation();
      },

      handleMouseover() {
        if (this.showSpeech || this.showFullMascot) {
          return;
        }

        if (this.animation.currentFrame < 350) {
          this.animation.pause();
        } else {
          this.animation.loop = false;
        }

        this.hovered = true;
      },

      handleMouseleave() {
        this.playAnimation();
        this.hovered = this.showSpeech ?? false;
      },
    },
  });
</script>

<style lang="scss" scoped>
  .container-fade-enter,
  .container-fade-leave-to {
    opacity: 0;
  }

  .mascot-burger {
    transition: opacity ease-in 0.3s;
    position: absolute;
    width: 100%;
    bottom: -7px;

    @include big-tablet {
      bottom: 4px;
    }

    &__wrapper {
      position: relative;
      overflow: hidden;
      min-height: 80px;
      height: 100%;

      @include hasMouse {
        min-height: 126px;
      }

      &--shown {
        overflow: visible;
      }

      &--closed {
        transition: height ease-in 0.6s;
        height: 0px;
        min-height: 0px;

        @include tablet {
          height: 40px;
          min-height: 40px;
        }
      }
    }

    &__lottie {
      cursor: pointer;
      margin-right: 8px;
      height: 126px;
      width: 145px;
      float: right;
      transform: translateY(0px);
      transition: transform ease-out 0.3s;
      margin-bottom: -48px;

      @include hasMouse {
        margin-bottom: 0;
      }

      @include big-tablet {
        height: 100px;
      }

      &--hovered {
        transform: translateY(-8px);
      }

      &--full-show {
        transform: translateY(-57px);

        @include big-tablet {
          transform: translateY(-48px);
        }
      }
    }
    &__speech {
      max-width: 208px;
      width: 208px;
      margin: 0 auto 12px;

      &--full-show {
        transform: translateY(-54px);

        @include big-tablet {
          transform: translateY(-48px);
        }
      }
    }
  }
</style>
