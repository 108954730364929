<template>
  <div
    class="nav-menu-container"
    data-cy="nav-menu-container"
    @mouseleave="$emit('mouse-left')"
    @mouseenter="$emit('mouse-enter')"
  >
    <div
      v-if="isNavVisible"
      class="dark-background"
      @click="hideNav"
    />

    <div class="nav-menu-container__logo">
      <component
        :is="isHomePage ? 'span' : 'a'"
        class="nav-menu-container__logo-link"
        :href="isHomePage ? null : '/'"
        :title="isHomePage ? null : 'Вернуться на главную страницу сайта'"
        :style="cursorVariable"
        @click="$emit('click-on-logo')"
      >
        <!-- !ЛОГО -->
        <SvgAsset
          class="lh-logo-black"
          :path="isHealthPath ? healthLogoPath : logoPath"
          width="152"
          height="32"
          alt="Лайфхакер"
        />
      </component>
      <SvgAsset
        class="burger-icon"
        :path="burgerIconPath"
        width="24"
        height="24"
      />
    </div>

    <ClientOnly>
      <Transition :name="getName">
        <!-- Открывается через v-show, потому что v-if слишком -->
        <!-- медленный на мобилках, это влияет на показатель INP -->
        <!-- и внутри ClientOnly чтобы размер страницы остался тем-же -->
        <NavMenu
          v-show="isNavMenuVisibleDesktop || isNavVisible"
          :burger-menu-items="burgerMenuItems"
          :social-network="socialNetwork"
          :is-nav-visible="isNavVisible"
          :hide-nav="hideNav"
          :is-nav-menu-visible-desktop="isNavMenuVisibleDesktop"
        />
      </Transition>
    </ClientOnly>
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';

  import NavMenu from '~/components/NavMenu/NavMenu.vue';

  import { IS_NEW_YEAR_LOGO } from '~/constants';
  import { isHealthPath, isHomePage } from '~/utils/router';
  import Transition from '~/components/transition.vue';

  import SvgAsset from '@devhacker/shared/components/SvgAsset.vue';
  import burgerIconPath from '@/assets/images/burger-icon.svg';
  import newYearLogoPath from '@/assets/images/new-year-logo-black.svg';
  import healthLogoPath from '@/assets/images/health-logo-black.svg';
  import lhLogoPath from '@/assets/images/lh-logo-black.svg';
  import { useIndexStore } from '~/store';
  import { useMenusStore } from '~/store/menus';
  import { mapState } from 'pinia';

  export default defineNuxtComponent({
    name: 'NavMenuContainer',

    components: {
      NavMenu,
      Transition,
      SvgAsset,
    },

    props: {
      isNavMenuVisibleDesktop: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
      },
    },

    computed: {
      ...mapState(useMenusStore, {
        burgerMenuItems: (state) => state.burgerMenuItems,
        socialNetwork: (state) => state.menus.burgerSubs,
      }),

      burgerIconPath: () => burgerIconPath,

      isNavVisible() {
        return useIndexStore().visibleModalWindows.includes('nav');
      },

      healthLogoPath(): string {
        return IS_NEW_YEAR_LOGO ? newYearLogoPath : healthLogoPath;
      },

      logoPath(): string {
        return IS_NEW_YEAR_LOGO ? newYearLogoPath : lhLogoPath;
      },

      cursorVariable() {
        return {
          '--cursor': isHomePage(useRoute().name) ? 'auto' : 'pointer',
        };
      },

      getName() {
        return this.isNavMenuVisibleDesktop ? 'fade' : 'nav-menu';
      },

      isHomePage() {
        return isHomePage(useRoute().name);
      },

      isHealthPath(): boolean {
        return isHealthPath(useRoute().path);
      },
    },

    methods: {
      hideNav() {
        useIndexStore().hideNav();
      },
    },
  });
</script>

<style lang="scss" scoped>
  .dark-background {
    background: rgba(black, 0.8);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    // увеличен чтобы перекрыть баннер adfox
    // на мобиле внизу страницы
    z-index: 9999998;
    transform: translateZ(1000px);

    // хак для исправления бага на Android, когда при скролле вверх
    // пропадает адресная строка браузера и подложка занимает
    // не всю высоту экрана;
    // 5px - дополнительно к высоте, т.к. все равно
    // остается небольшой зазор
    min-height: calc(100vh + 5px);

    @include hasTouch {
      min-height: auto;
    }
    @include hasMouse {
      display: none;
    }
  }

  .nav-menu-container {
    @include hasMouse {
      position: sticky;
      top: 48px;
    }

    &__logo {
      cursor: var(--cursor);
      display: none;
      border-bottom: 1px solid #e7e7e7;
      user-select: none;

      .white & {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        height: 48px;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 0 24px;

        @include big-tablet {
          flex-direction: row;
        }

        .lh-logo-black {
          width: 147px;
          height: 32px;
          z-index: 2;
        }
        .burger-icon {
          width: 24px;
          height: 24px;
          z-index: 2;
        }
      }
    }

    &__logo-link {
      display: flex;
    }

    &.white {
      display: none;
      @include hasMouse {
        width: 240px;
        display: block;
        position: absolute;
        top: 0;
        background: white;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
        border-radius: 0 0 4px 4px;
      }
    }

    .nav-menu-enter-active,
    .nav-menu-leave-active {
      transition:
        opacity 0.3s ease,
        transform 0.3s ease;
      will-change: transform, opacity;
    }
    .nav-menu-enter {
      transform: translateZ(1000px) translateY(-80%);
    }
    .nav-menu-leave-to {
      transform: translateY(-50%);
      opacity: 0;
    }
  }
</style>
