<template>
  <div class="nav-menu-below-header__container">
    <HeaderArrowMenu
      :items="items"
      theme="light"
      @click-menu-item="onClickMenuItem"
    />
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import HeaderArrowMenu from '~/components/HeaderArrowMenu.vue';

  import { isHomeLink, getRelativeLink, getFullUrl } from '~/utils';
  import { HOME_URL, WORDPRESS_API_URL } from '~/constants/config';

  export default defineNuxtComponent({
    name: 'NavMenuBelowHeader',

    components: {
      HeaderArrowMenu,
    },

    props: {
      list: {
        type: Array as PropType<Array<MenuItem>>,
        required: true,
      },
    },

    computed: {
      items(): Array<MenuItem> {
        return this.list.map((item) => {
          const result = { ...item };

          result.isInternalUrl = isHomeLink(item.link);
          result.isActive = this.isActive(item.link);
          result.link = result.isInternalUrl ? getRelativeLink(item.link) : item.link;

          return result;
        });
      },
    },

    methods: {
      onClickMenuItem(item: MenuItem): void {
        this.$sendYandexMetrika({
          level1: 'Клик_Хедер',
          level4: getFullUrl(item.link),
          level5: item.title,
          level6: 'Второй уровень',
        });
      },
      isActive(link: string): boolean {
        const linkWithoutHostName = this.getLinkWithHostName(link);
        return useRoute().path === linkWithoutHostName;
      },
      getLinkWithHostName(link: string): string {
        return link.replace(WORDPRESS_API_URL, '').replace(HOME_URL, '');
      },
    },
  });
</script>

<style lang="scss">
  .nav-menu-below-header__container {
    display: none;
    overflow: hidden;
    position: relative;
    z-index: 2;

    width: 100%;
    height: 40px;
    padding: 0 24px;

    background-color: white;
    border-bottom: 1px solid #e7e7e7;
    min-height: 48px;

    @include big-tablet {
      display: flex;
      justify-content: center;
    }
  }
</style>
