<template>
  <ClientOnly>
    <div
      v-click-outside="handleClickOutside"
      class="the-bell__bell"
      :class="bellClasses"
      @mouseenter="handleNotificationsMouseEnter"
      @mouseleave="handleNotificationsMouseLeave"
      @click="handleNotificationsClick"
    >
      <div
        v-show="unviewedNotificationsCount > 0"
        class="the-bell__unviewed"
      >
        {{ unviewedNotificationsCountEdited }}
      </div>
      <img
        class="the-bell__bell-icon"
        src="./images/bell-white.svg"
        alt="Иконка уведомлений"
      />
      <div
        v-show="notificationsVisible"
        class="the-bell__bell-notifications"
      >
        <BellNotifications
          :notifications-visible="notificationsVisible"
          @close-notifications="hideNotifications"
        >
          <slot name="bellFooter" />
        </BellNotifications>
      </div>
    </div>
  </ClientOnly>
</template>

<script lang="ts">
  import { vOnClickOutside } from '@vueuse/components';
  import BellNotifications from '../BellNotifications/BellNotifications.vue';
  import { mapState } from 'pinia';
  import { usePusherStore } from '~/store/pusher';

  export default defineNuxtComponent({
    name: 'TheBell',

    directives: {
      clickOutside: vOnClickOutside,
    },

    components: {
      BellNotifications,
    },

    props: {
      isAuthenticated: {
        required: true,
        type: Boolean,
      },
      doAuthorization: {
        required: true,
        type: Function,
      },
    },

    data() {
      return {
        notificationsVisible: false,
        isCursorOnIcon: false,
      };
    },

    computed: {
      ...mapState(usePusherStore, [
        'unviewedNotificationsCount',
        'notificationsRequested',
        'notifications',
      ]),

      isEmptyVisible(): boolean {
        return this.notificationsRequested && this.notifications.length < 1;
      },
      bellClasses(): Record<string, boolean> {
        return {
          'header__bell--all-read': this.unviewedNotificationsCount < 1,
        };
      },
      unviewedNotificationsCountEdited(): string {
        const count = this.unviewedNotificationsCount;
        return count < 100 ? count.toString() : '99+';
      },
    },
    methods: {
      handleNotificationsMouseEnter() {
        this.isCursorOnIcon = true;
        if (this.isAuthenticated) {
          this.showNotifications();
        }
      },
      handleNotificationsMouseLeave() {
        this.isCursorOnIcon = false;
        this.hideNotifications();
      },
      handleNotificationsClick() {
        if (this.isAuthenticated) {
          this.showNotifications();
        } else {
          this.doAuthorization();
        }
      },
      handleClickOutside() {
        this.hideNotifications();
      },
      showNotifications() {
        const resetBellCount = () => {
          usePusherStore().resetUnviewedNotificationsCountOnServer();
        };

        if (!this.notificationsVisible) {
          if (this.notificationsRequested === false) {
            // * очищаем все уведомления
            usePusherStore().emptyNotifications();
            // * меняем состояние notificationsRequested = true
            usePusherStore().setNotificationsRequestedAction();
            return usePusherStore()
              .fetchNotifications()
              .then(() => {
                if (!this.notificationsVisible && this.unviewedNotificationsCount > 0) {
                  resetBellCount();
                }
                if (this.isCursorOnIcon) {
                  this.notificationsVisible = true;
                }
              })
              .catch((err) => {
                this.$emit('fetch-notifications-error', err);
              });
          }
          if (!this.notificationsVisible && this.unviewedNotificationsCount > 0) {
            resetBellCount();
          }

          if (this.isCursorOnIcon) {
            this.notificationsVisible = true;
          }
        }
      },
      hideNotifications() {
        if (this.notificationsVisible) {
          this.notificationsVisible = false;
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
  .the-bell {
    &__bell-and-ava {
      display: flex;
      align-items: center;
    }

    &__bell-notifications {
      position: absolute;
      top: 100%;
      width: 320px;
      z-index: 1;
      right: -52px;
      @include tablet {
        right: 0;
      }
    }

    &__bell {
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      height: 48px;
      width: 48px;

      svg circle {
        fill: none;
      }

      @include hover {
        background-color: rgba(255, 255, 255, 0.2);
        // '#df3b1b'
      }
    }

    &__unviewed {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: solid 2px var(--main-color, #ed5e42);
      background: #222222;
      color: white;
      font-size: 10px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
