<template>
  <div
    class="search-input-mobile"
    :style="cssVariables"
  >
    <SearchInput
      auto-focus
      @search="handleSearch"
    />
  </div>
</template>

<script lang="ts">
  import { useModal } from '@devhacker/shared/plugins/modal/useModal';
  import SearchInput from './SearchInput.vue';
  import { isHealthPath } from '~/utils/router';

  export default defineNuxtComponent({
    name: 'TopHeaderSearchActionsheet',

    components: {
      SearchInput,
    },

    computed: {
      cssVariables(): string {
        return `
         --main-color-search-mobile: ${isHealthPath(useRoute().path) ? ' #2962f9' : '#ed5e42'}
      `;
      },
    },

    methods: {
      handleSearch() {
        useModal().close();
      },
    },
  });
</script>

<style lang="scss" scoped>
  .search-input-mobile {
    padding: 4px 8px;
    background: var(--main-color-search-mobile);
    width: 100vw;
  }
</style>
