<script setup lang="ts">
  import { useMenusStore } from '~/store/menus';
  import NavMenuBelowHeader from '~/components/NavMenuBelowHeader.vue';

  const menuList = useMenusStore().menus.second?.items ?? [];
</script>

<template>
  <NavMenuBelowHeader :list="menuList" />
</template>
