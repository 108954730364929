<template lang="pug">
button.arrow-button(@click='$emit("scroll")' :class="arrowClass")
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import type { TTheme } from '~/components/ArrowMenu/ArrowMenu';
  export default defineNuxtComponent({
    name: 'ArrowMenuButton',

    emits: ['scroll'],

    props: {
      theme: {
        type: String as PropType<TTheme>,
        required: true,
      },

      icon: {
        type: String as PropType<string>,
        required: true,
      },
    },

    computed: {
      arrowClass(): Record<string, any> {
        const className = 'arrow-button';
        const { theme } = this;

        return {
          [`${className}--${theme}`]: theme,
        };
      },
    },
  });
</script>

<style lang="scss" scoped>
  .arrow-button {
    background: none;
    border: none;
    width: 32px;
    height: 100%;
    padding: 16px 8px;
    cursor: pointer;

    display: none;
    align-items: center;
    justify-content: flex-end;

    @include big-tablet {
      display: flex;
    }

    &::before {
      position: absolute;
      pointer-events: none;
      content: '';
      width: 16px;
      height: 16px;
      mask-image: url('./img/arrow.svg');

      top: 50%;
      transform: translateY(-50%);
    }

    &--red {
      &::before {
        background-color: #ffffff;
      }
    }

    &--light {
      &::before {
        background-color: #969698;
      }

      @include hover {
        &::before {
          background-color: black;
        }
      }
    }
  }
</style>
