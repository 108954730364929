<template>
  <ErrorBoundary module-name="TopHeaderContainer">
    <header
      id="top-header-container"
      ref="topHeaderContainer"
      class="top-header-container"
    >
      <TopHeader
        v-if="!searchActive"
        :is-authenticated="isAuthenticated"
        :user-avatar-src="userAvatarSrc"
        :do-authorization="doAuthorization"
        :user-integer-id="userIntegerId"
        :logout="logout"
        :primary-nav-menu-items="primaryNavMenuItems"
        :show-nav="showNav"
        @click-on-logo="onClickOnLogo"
        @click-on-search="onClickSearch"
      />
      <TopHeaderSearch
        v-else
        @click-on-close="closeSearch"
        @search="closeSearch"
      />
    </header>
  </ErrorBoundary>
</template>

<script lang="ts">
  import { getFullUrl } from '~/utils';

  import TopHeader from '~/components/TopHeader/TopHeader.vue';
  import TopHeaderSearch from '~/components/TopHeaderSearch/TopHeaderSearch.vue';
  import TopHeaderSearchActionsheet from '~/components/TopHeaderSearch/TopHeaderSearchActionsheet.vue';
  import { useMenusStore } from '~/store/menus';
  import AuthWindow from '~/components/Auth/AuthWindow.vue';
  import { leavePrivateChannel } from '~/components/Pusher/websockets-functions';
  import { useIndexStore } from '~/store';
  import { usePusherStore } from '~/store/pusher';
  import ErrorBoundary from '@devhacker/shared/components/ErrorBoundary.vue';
  import { useModal } from '@devhacker/shared/plugins/modal/useModal';

  export default defineNuxtComponent({
    name: 'TopHeaderContainer',
    components: {
      TopHeader,
      TopHeaderSearch,
      ErrorBoundary,
    },

    data() {
      return {
        searchActive: false as boolean,
      };
    },
    computed: {
      primaryNavMenuItems(): Array<MenuItem> {
        return useMenusStore().menus?.projects?.items ?? [];
      },
      user(): IUser | null {
        return useIndexStore().user ?? null;
      },
      userIntegerId(): number | null {
        return this.user?.integer_id ?? null;
      },
      userAvatarSrc(): string {
        const { user } = this;

        if (user) {
          const smallAvatar = user.avatars.find((avatar) => avatar.size === 'small');
          const middleAvatar = user.avatars.find((avatar) => avatar.size === 'middle');
          if (middleAvatar && middleAvatar.url) {
            return middleAvatar.url;
          }
          if (smallAvatar && smallAvatar.url) {
            return smallAvatar.url;
          }
        }
        return '';
      },
      isAuthenticated(): boolean {
        // const { user } = this.$store.state as IRootState;
        // return user !== null;

        return Boolean(this.user);
      },
    },
    methods: {
      doAuthorization(location = 'колокольчик'): void {
        // @ts-expect-error no types for window
        window.accounts?.id?.cancel();

        useModal().open(
          AuthWindow,
          {
            eventElement: location,
          },
          {
            transition: 'modal-fade',
            // disableScroll: true,
          },
        );
      },
      logout() {
        leavePrivateChannel();
        usePusherStore().resetStore();
        useIndexStore().logout();
      },
      showNav() {
        useIndexStore().showNav();
      },
      onClickOnLogo(): void {
        this.$sendYandexMetrika({
          level1: 'Клик_Лого',
          level4: getFullUrl('/'),
        });
      },
      onClickSearch() {
        if (window.innerWidth < 768) {
          useModal().open(
            TopHeaderSearchActionsheet,
            {},
            {
              transition: 'modal-fade',
              align: 'top',
            },
          );
        } else {
          this.searchActive = true;
        }
      },
      closeSearch() {
        this.searchActive = false;

        if (window.innerWidth < 768) {
          useModal().close();
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
  .top-header-container {
    height: 48px;
    position: sticky;
    top: 0;
    z-index: 13;
  }
</style>
