<template>
  <div
    class="nav-menu"
    :class="cssClasses"
    data-cy="nav-menu"
  >
    <ScrollContainer
      ref="perfectScrollbar"
      class="nav-menu__items"
      :options="{ suppressScrollX: true }"
    >
      <NavMenuCategories :burger-menu-items="burgerMenuItems" />
      <NavMenuSocials
        :social-title="socialTitle"
        :social-items="socialItems"
      />
      <BuildInfo v-if="!isProductionStand" />
      <MascotBurger
        v-if="!$isAMP && !isHealth"
        @close-mascot="handleCloseMascot"
      />
    </ScrollContainer>

    <a
      href="/"
      name="Вернуться на главную страницу сайта"
      @click="$emit('click-on-logo')"
    >
      <div class="nav-menu__logo">
        <!-- !ЛОГО -->
        <SvgAsset
          class="nav-menu__logo-icon"
          :path="isHealthPath ? healthLogoPath : logoPath"
          width="152"
          height="32"
          alt="Лайфхакер"
        />
        <span
          v-if="isHealth"
          class="nav-menu__health-title"
        >
          Здоровье
        </span>
      </div>
      <div class="nav-menu__separator" />
    </a>

    <div
      class="nav-menu__close"
      data-cy="nav-menu-close"
      @click="hideNav"
    >
      <SvgAsset
        :path="menuClosePath"
        width="24"
        height="24"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';

  import BuildInfo from '@devhacker/shared/features/BuildInfo/BuildInfo.vue';
  import SvgAsset from '@devhacker/shared/components/SvgAsset.vue';
  import NavMenuCategories from './NavMenuCategories.vue';

  import NavMenuSocials from './NavMenuSocials.vue';

  import type { Menu } from '~/store/menus';
  import MascotBurger from '@/components/Mascot/MascotBurger.vue';
  import { isHealthPath } from '~/utils/router';
  import { isProductionStand } from '~/constants/config';
  import { IS_NEW_YEAR_LOGO } from '~/constants';
  import ScrollContainer from '../lh-ui/ScrollContainer/ScrollContainer.vue';

  import newYearLogoPath from '@/assets/images/new-year-logo-black.svg';
  import healthLogoPath from '@/assets/images/health-logo-black.svg';
  import lhLogoPath from '@/assets/images/lh-logo-black.svg';
  import menuClosePath from '@/assets/images/menu-close-black.svg';

  export default defineNuxtComponent({
    components: {
      NavMenuSocials,
      NavMenuCategories,
      MascotBurger,
      BuildInfo,
      SvgAsset,
      ScrollContainer,
    },

    props: {
      burgerMenuItems: {
        type: Array as PropType<Array<MenuItem>>,
        required: true,
      },
      socialNetwork: {
        type: Object as PropType<Menu>,
        required: true,
      },
      isNavVisible: {
        type: Boolean as PropType<boolean>,
        required: true,
      },
      hideNav: {
        type: Function as PropType<() => void>,
        required: true,
      },
      isNavMenuVisibleDesktop: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        isMascotClosed: false as boolean,
      };
    },

    computed: {
      menuClosePath: () => menuClosePath,
      isProductionStand: () => isProductionStand,

      healthLogoPath(): string {
        return IS_NEW_YEAR_LOGO ? newYearLogoPath : healthLogoPath;
      },

      logoPath(): string {
        return IS_NEW_YEAR_LOGO ? newYearLogoPath : lhLogoPath;
      },

      isHealth(): boolean {
        return isHealthPath(useRoute().path);
      },

      cssClasses(): { [propName: string]: boolean } {
        return {
          'nav-menu--visible': this.isNavVisible,
          'nav-menu--hidden': !this.isNavVisible,
        };
      },

      socialTitle() {
        return this.socialNetwork?.title || 'Мы в соцсетях';
      },

      socialItems() {
        return this.socialNetwork?.items ?? [];
      },

      isHealthPath(): boolean {
        return isHealthPath(useRoute().path);
      },
    },

    // watch: {
    // isNavMenuVisibleDesktop(newVal: boolean): void {
    //   if (newVal) {
    //     this.updatePerfectScrollbar();
    //   }
    // },
    //
    // isNavVisible(newVal: boolean): void {
    //   if (newVal) {
    //     this.updatePerfectScrollbar();
    //   }
    // },
    // },

    methods: {
      // updatePerfectScrollbar() {
      //   // Если элемент изначально не отображается, то скроллбар не может рассчитать свои размеры.
      //   // Поэтому при появлении элемента на экране нужно вызвать метод update.
      //   // См issue https://github.com/mdbootstrap/perfect-scrollbar/issues/476
      //   // и документацию библиотеки perfect-scrollbar
      //   try {
      //     // @ts-ignore
      //     this.$refs.perfectScrollbar.update();
      //   } catch (error) {
      //     console.warn(error);
      //   }
      // },

      handleCloseMascot() {
        this.isMascotClosed = true;
      },
    },
  });
</script>

<style lang="scss" scoped>
  .build-info-container {
    font-family: $mainFont;
    font-size: 18px;

    @include desktop {
      font-size: 16px;
    }
  }
</style>

<style scoped lang="scss">
  .nav-menu {
    padding-top: 48px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 320px;
    max-width: 320px;
    // увеличен чтобы перекрыть баннер adfox
    // на мобиле внизу страницы
    z-index: 2147483644;
    transition: transform 300ms;
    background: white;
    display: flex;
    flex-direction: column;

    &--visible {
      transform: translate3d(0, 0, 1000px);
    }

    &--hidden {
      transform: translate3d(-100%, 0, 1000px);
    }

    @include hasTouch {
      min-height: auto;
    }

    @include hasMouse {
      &--visible,
      &--hidden {
        transform: none;
      }
      position: relative;
      padding: 0px;
      background: initial;
      z-index: initial;
      max-width: 240px;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      height: 100vh;
    }

    &__items {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      @include alwaysShowScrollbar;
    }

    &__logo {
      position: absolute;
      top: 12px;
      left: 44px;
      display: flex;
      align-items: center;

      @include hasTouch {
        top: 7px;
      }
      &-icon {
        width: 110px;
        height: 24px;
        @include hasTouch {
          width: 145px;
          height: 32px;
        }
      }
      @include hasTouch {
        left: 64px;
      }

      @include hasMouse {
        display: none;
      }
    }

    &__health-title {
      font-family: $secondFont;
      font-size: 11px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.04em;
      text-align: left;
      text-transform: uppercase;
      color: black;
      height: 24px;
      padding-top: 1px;
      border-left: 1px solid #000;
      margin-left: 6px;
      padding-left: 6px;

      @include big-mobile {
        margin-left: 8px;
        padding-left: 8px;
        font-size: 12px;
      }
    }

    &__separator {
      &::before {
        position: absolute;
        content: '';
        height: 1px;
        background: #e7e7e7;
        width: 100%;
        left: 0;
        top: 47px;
        @include hasMouse {
          height: 0;
        }
      }
    }

    &__close {
      position: absolute;
      top: 11px;
      left: 11px;
      cursor: pointer;
      @include hasTouch {
        left: 20px;
      }
      @include hasMouse {
        display: none;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
</style>
