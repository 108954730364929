<template>
  <div class="top-header-search">
    <div class="header-search-content">
      <SearchInput
        auto-focus
        @search="handleSearch"
      />
      <button
        class="header-search-content__btn"
        @click="clickClose"
      >
        Закрыть
      </button>
    </div>
  </div>
</template>

<script lang="ts">
  import SearchInput from './SearchInput.vue';

  export default defineNuxtComponent({
    name: 'TopHeaderSearch',

    components: {
      SearchInput,
    },

    methods: {
      handleSearch(query: string) {
        this.$emit('search', query);
        this.$emit('click-on-close');
      },

      clickClose() {
        this.$emit('click-on-close');

        this.$sendYandexMetrika({
          level1: 'Событие_Поиск',
          level6: 'Закрытие поиска',
        });
      },
    },
  });
</script>

<style lang="scss" scoped>
  .top-header-search {
    height: 48px;
    width: 100%;
    background: var(--main-color);
  }
  .header-search-content {
    display: flex;
    height: 100%;
    max-width: 1248px;
    padding: 0 8px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    @include tablet {
      padding: 0 16px 0 24px;
    }

    &__btn {
      font-family: $mainFont;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      padding: 0 16px;
      height: 100%;
      border: none;

      margin-left: 4px;
      background: var(--main-color);
      color: #fff;
      position: relative;

      @include hover {
        cursor: pointer;
        &::before {
          position: absolute;
          background: #fff;
          opacity: 0.2;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
        }
      }
    }
  }
</style>
