<template lang="pug">
  .arrow-menu-test(:class='arrowMenuClass')
    // Uses sibling selector, so before wrapper itself
    // Disabled by default so it doesn't flash on page load, swiper activates it when needed
    ArrowMenuButton.arrow-menu-test__button.arrow-menu-test__button--left(
      ref='leftBtn'
      :theme='theme'
      :disabled="true"
      icon='arrow'
    )

    ArrowMenuButton.arrow-menu-test__button.arrow-menu-test__button--right(
      ref='rightBtn'
      :theme='theme'
      icon='arrow'
      :disabled="true"
    )

    nav.swiper.arrow-menu-test__navigation(
      ref='arrowMenuWrapp'
    )
      .swiper-wrapper.arrow-menu-test__list(ref='list')
        ArrowMenuItem(
          v-for="item of items"
          :item="item" :key="item.id"
          :theme="theme"
          :class="childClass"
          @appear-item="addItemToAppeared"
          :appearedItems="appearedItems"
          @click-menu-item="$emit('click-menu-item', $event)"
        )

</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import type { Swiper } from 'swiper';
  import type { TTheme } from '@/components/ArrowMenu/ArrowMenu';
  import ArrowMenuItem from '~/components/ArrowMenu/ArrowMenuItem.vue';
  import ArrowMenuButton from '~/components/ArrowMenu/ArrowMenuButton.vue';
  import 'swiper/css';

  export default defineNuxtComponent({
    name: 'ArrowMenu',
    components: {
      ArrowMenuItem,
      ArrowMenuButton,
    },

    props: {
      theme: {
        type: String as PropType<TTheme>,
        default: 'light',
      },

      items: {
        type: Array as PropType<MenuItem[]>,
        required: true,
      },
    },

    data() {
      return {
        scrollLeft: 0,
        visibleItem: 0,
        swiper: reactive({}) as Swiper,
        appearedItems: [] as number[],
      };
    },

    computed: {
      arrowMenuClass(): Record<string, string> {
        const className = 'arrow-menu-test';
        const theme = this.theme;
        return {
          [`${className}--${theme}`]: theme,
        };
      },
    },

    methods: {
      addItemToAppeared(id: number) {
        this.appearedItems.push(id);
      },
    },

    async setup() {
      const swiper = ref<null | Swiper>(null);
      const arrowMenuWrapp = ref<null | HTMLElement>(null);
      const leftBtn = ref<null | ComponentPublicInstance>(null);
      const rightBtn = ref<null | ComponentPublicInstance>(null);

      const childClass = 'arrow-menu-item';

      onMounted(async () => {
        const { default: Swiper } = await import('swiper');
        const { Navigation } = await import('swiper/modules');

        if (!arrowMenuWrapp.value) return;

        // TODO: вернуть swiper или альтернативу
        swiper.value = new Swiper(arrowMenuWrapp.value, {
          modules: [Navigation],
          loop: false,
          slidesPerView: 'auto',
          resistanceRatio: 0,
          watchOverflow: false,
          centerInsufficientSlides: true,
          slideClass: childClass ?? 'swiper-slide',

          navigation: {
            nextEl: rightBtn.value?.$el,
            prevEl: leftBtn.value?.$el,
          },
        });
      });

      return {
        swiper,
        arrowMenuWrapp,
        leftBtn,
        rightBtn,
        childClass,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @use 'swiper/scss' as *;

  $left-fade--light: linear-gradient(90deg, #ffffff 71.62%, rgba(255, 255, 255, 0) 94.25%);
  $right-fade--light: linear-gradient(270deg, #ffffff 71.62%, rgba(255, 255, 255, 0) 94.25%);
  $left-fade--red: linear-gradient(90deg, var(--main-color) 71.62%, rgba(237, 94, 66, 0) 94.25%);
  $right-fade--red: linear-gradient(270deg, var(--main-color) 71.62%, rgba(237, 94, 66, 0) 94.25%);
  $right-fade--light--mobile: linear-gradient(
    270deg,
    #ffffff 32.22%,
    rgba(255, 255, 255, 0.4) 61.04%,
    rgba(255, 255, 255, 0) 94.25%
  );
  $left-fade--light--mobile: linear-gradient(
    90deg,
    #ffffff 32.22%,
    rgba(255, 255, 255, 0.4) 61.04%,
    rgba(255, 255, 255, 0) 94.25%
  );

  .arrow-menu-test {
    position: relative;
    width: 100%;
    height: 48px;
    overflow-x: hidden;
    user-select: none;

    display: flex;
    justify-content: center;
    align-items: center;

    &__navigation {
      overflow: hidden;
      position: relative;
      height: 100%;

      //@include tablet {
      //  padding: 0;
      //}

      // * fades
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 24px;
        pointer-events: none;
        height: 100%;
        top: 0;
        z-index: 4;
        opacity: 0;
        transition: opacity 0.1s linear;
        @include big-tablet {
          width: 60px;
        }
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    &__button {
      position: absolute;
      z-index: 3;
      top: 50%;
      transform: translateY(-50%);

      &:disabled {
        display: none;
      }

      &--left {
        transform: translateY(-50%);
        left: 0;

        &:not(:disabled) {
          & ~ .arrow-menu-test__navigation::before {
            opacity: 1;
          }
        }
      }

      &--right {
        transform: scaleX(-1) translateY(-50%);
        right: 0;

        &:not(:disabled) {
          & ~ .arrow-menu-test__navigation::after {
            opacity: 1;
          }
        }
      }
    }

    &__list {
      position: relative;
      display: flex;

      align-items: center;
      width: 100%;
      height: 100%;
      list-style: none;
    }

    &__item {
      width: fit-content;
      padding: 8px 0;

      @include tablet {
        padding: 0;
      }
    }
  }

  .arrow-menu-test--light {
    .arrow-menu-test {
      &__navigation {
        &::before {
          background: $left-fade--light--mobile;

          @include big-tablet {
            background: $left-fade--light;
          }
        }

        &::after {
          background: $right-fade--light--mobile;

          @include big-tablet {
            background: $right-fade--light;
          }
        }
      }
    }
  }

  .arrow-menu-test--red {
    .arrow-menu-test {
      &__navigation {
        &::before {
          background: $left-fade--red;
        }

        &::after {
          background: $right-fade--red;
        }
      }
    }
  }

  .arrow-menu-test--preview {
    .arrow-menu-test {
      &__list {
        position: relative;
      }
    }
  }
</style>
