<template>
  <ClientOnly>
    <div
      class="bell-notifications"
      :class="rootClasses"
    >
      <ScrollContainer
        v-show="!isEmptyVisible"
        ref="perfectScrollbar"
        class="bell-notifications__wrapper"
      >
        <a
          v-for="n in lastNotifications"
          :key="n._id"
          :href="getNotificationLink(n)"
          :class="getNotificationClasses(n)"
          class="bell-notifications__item"
          @click="openNotification(n)"
        >
          <div
            class="bell-notifications__item-photo"
            :style="getPhotoStyle(n)"
          />
          <div class="bell-notifications__item-content-and-date">
            <div
              class="bell-notifications__item-content"
              v-html="getNotificationContent(n)"
            />
            <!-- TODO: Check if event_at exitts -->
            <div class="bell-notifications__item-date">{{ getHumanReadableDate(n.event_at) }}</div>
          </div>
        </a>
      </ScrollContainer>
      <div
        v-show="isEmptyVisible"
        class="bell-notifications__empty"
      >
        <div
          class="bell-notifications__empty-close"
          @click.stop="onClose"
        >
          <img
            src="./images/close-notifications.svg"
            alt="иконка закрыть уведомления"
          />
        </div>
        <img
          src="./images/no-notifications.svg"
          alt="иконка нет уведомлений"
        />
        <div class="bell-notifications__empty-text">Здесь будут храниться ваши уведомления</div>
      </div>
      <div
        v-show="!isEmptyVisible"
        class="bell-notifications__footer"
      >
        <slot />
      </div>
    </div>
  </ClientOnly>
</template>

<script lang="ts">
  import {
    getHumanReadableDate,
    getNotificationContent,
    getNotificationLink,
    getPhotoStyle,
  } from '../utils.client';
  import { usePusherStore, type TNotification } from '~/store/pusher';
  import ScrollContainer from '~/components/lh-ui/ScrollContainer/ScrollContainer.vue';

  export default defineNuxtComponent({
    name: 'BellNotifications',

    components: {
      ScrollContainer,
    },

    props: {
      notificationsVisible: {
        type: Boolean,
        required: true,
      },
    },

    computed: {
      notifications() {
        return usePusherStore().notifications;
      },

      notificationsRequested(): boolean {
        return usePusherStore().notificationsRequested;
      },

      lastNotifications(): TNotification[] {
        return this.notifications.slice(0, 10);
      },
      isEmptyVisible(): boolean {
        return this.notificationsRequested && this.notifications.length < 1;
      },
      rootClasses(): Record<string, boolean> {
        return {
          'bell-notifications--empty': this.isEmptyVisible,
        };
      },
    },
    // watch: {
    //   notificationsVisible(newVal) {
    //     if (newVal) {
    //       // Если элемент изначально не отображается, то скроллбар не может рассчитать свои размеры.
    //       // Поэтому при появлении элемента на экране нужно вызвать метод update.
    //       // См issue https://github.com/mdbootstrap/perfect-scrollbar/issues/476
    //       // и документацию библиотеки perfect-scrollbar
    //       try {
    //         // @ts-ignore
    //         this.$refs.perfectScrollbar.update();
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     }
    //   },
    // },
    methods: {
      getPhotoStyle,
      getNotificationContent,
      getNotificationLink,
      getHumanReadableDate,
      getNotificationClasses(notification: TNotification) {
        const IS_READ = notification.is_read;
        return {
          'bell-notifications__item--is-read': IS_READ,
        };
      },
      showAllNotifications() {
        this.$emit('clickShowAllNotifications');
      },
      openNotification(notification: TNotification) {
        usePusherStore().setReadToNotification(notification._id);
      },
      onClose() {
        this.$emit('close-notifications');
      },
    },
  });
</script>
<style lang="scss" scoped>
  $size: 7px;
  $blue: #2962f9;

  .bell-notifications {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    position: relative;
    padding-bottom: 56px;
    width: 100%;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow: hidden;
    z-index: 1;

    :deep(.ps__rail-y) {
      right: 8px !important;
    }

    &--empty {
      padding: 0;
    }

    &__wrapper {
      max-height: 380px;
      .ps {
        padding: 0;
      }
    }

    &_all-items-link {
      @include removeHyperlinkFormatting();
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 56px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      color: $blue;
      box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.05);
    }

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: $size solid transparent;
      border-right: $size solid transparent;
      border-bottom: $size solid white;
      right: 22px;
      top: -7px;
      @include mobile {
        display: none;
      }
    }

    &__item {
      padding: 16px 20px 16px 8px;
      background: #f3f5fc;
      display: flex;
      color: inherit;
      text-decoration: inherit;
      @include hover {
        background-color: #e1eaff;
        color: inherit;
      }
    }

    &__item--is-read {
      background: white;
    }

    &__item-photo {
      flex: 0 0 32px;
      height: 32px;
      margin-right: 8px;
      background: center top / auto 100% no-repeat;
      border-radius: 20px;
      // перезаписывает стили главной
      &:empty {
        margin-right: 8px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &__item-content {
      font-size: 14px;
      line-height: 1.28;
      margin-bottom: 8px;
      @include lineClampEllipsis(3);
      word-break: break-word;
    }

    &__item-date {
      font-size: 12px;
      height: 16px;
      line-height: 16px;
      color: #7b7b7b;
    }

    &__empty {
      padding: 16px 16px 32px 16px;
      text-align: center;
      position: relative;

      svg {
        transform: scale(0.75);
      }
    }

    &__empty-text {
      @include fontH4AccentNormalText;
      text-align: center;
    }

    &__empty-close {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      cursor: pointer;
      padding: 12px;

      @include tablet {
        padding: 20px 16px;
      }
    }
  }

  .bell-notifications__item--is-read + .bell-notifications__item--is-read {
    position: relative;

    &:after {
      position: absolute;
      width: calc(100% - 32px);
      height: 1px;
      left: 16px;
      top: 0;
      background: #e7e7e7;
      content: '';
    }
  }
</style>
