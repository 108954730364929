<template lang="pug">
  .arrow-menu-wrapper
    ArrowMenu.arrow-menu-test(:theme="theme" :items="items")
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import ArrowMenu from '~/components/ArrowMenu.vue';
  export default defineNuxtComponent({
    name: 'HeaderArrowMenu',
    components: {
      ArrowMenu,
    },
    props: {
      items: {
        type: Array as PropType<MenuItem[]>,
        required: true,
      },
      theme: {
        type: String as PropType<string>,
        default: 'light',
      },
    },
  });
</script>

<style lang="scss" scoped>
  .arrow-menu-wrapper {
    max-width: 1248px;
    width: 100%;
    padding: 0 8px;
  }
</style>
